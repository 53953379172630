
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$EcuPerformance-primary: mat-palette((
    50 : #ffffff,
    100 : #dfcca0,
    200 : #ebd8a9,
    300 : #e2cd97,
    400 : #dac38a,
    500 : #dabe75,
    600 : #d1b264,
    700 : #c3a557,
    800 : #b3964f,
    900 : #a38741,
    A100 : #ffffff,
    A200 : #ffe2e2,
    A400 : #ffafaf,
    A700 : #ff9696,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
));
$EcuPerformance-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$EcuPerformance-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$EcuPerformance-theme: mat-light-theme($EcuPerformance-primary, $EcuPerformance-accent, $EcuPerformance-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($EcuPerformance-theme);
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

@font-face {
    font-family: 'ds-digi';
    src: url('./fonts/DS-DIGIB.TTF');
}

:root{
    --header-height:50px;
    --drawer-width:200px;
    --bg-transmissao:#0000ff;
    --turn-color:#ff8936;
    --selfturn-color:#89d9ff;
}

body{
    margin:0px;
    padding: 0px;
    font-family: 'Open Sans', sans-serif;
}
html, body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue",
    sans-serif;
    overflow: hidden!important;
}
.toast-container .ngx-toastr{
    box-shadow: none;
}
.cursor-pointer{
    cursor: pointer;
}

.dialog mat-dialog-container{
    padding:0px;
    background-color: transparent;
}
.scrollbar{
    padding-bottom:130px !important;
}
.scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 6px;
    width:8px;
}
.scrollbar::-webkit-scrollbar-thumb:hover{
    background: rgba(255, 255, 255, 0.6);
}
.scrollbar::-webkit-scrollbar-track{
    background: transparent;
    width:8px;
    border:2px solid transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.content{
    overflow: auto;
}

@media screen and (min-width:992px){
    :root{
        --header-height:80px;
    }
}

.bg-color .mat-dialog-container{
    background: rgba(0, 0, 0);
    color: white;

}